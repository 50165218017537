<form [formGroup]="form" (ngSubmit)="submitForm()">
    <h1>{{formTitle}}</h1>
    <ng-container>
        <div>
            <mat-form-field class="full-width-input" appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Email" formControlName="email">
                <mat-error>
                    Please provide a valid email address
                </mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field class="full-width-input" appearance="outline">
                <mat-label>First Name</mat-label>
                <input matInput placeholder="First Name" formControlName="firstname">
                <mat-error>
                    Please provide a valid first name
                </mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field class="full-width-input" appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput placeholder="Last Name" formControlName="lastname">
                <mat-error>
                    Please provide a valid last name
                </mat-error>
            </mat-form-field>
        </div>
        
        <div>
            <mat-form-field class="full-width-input" appearance="outline">
                <mat-label>Phone</mat-label>
                <input matInput placeholder="Phone" formControlName="phoneNumber">
                <mat-error>
                    Please provide a valid phone number
                </mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field class="full-width-input" appearance="outline">
                <mat-label>Country</mat-label>
                <mat-select formControlName="country" placeholder="Country">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="countryFilterControl" placeholderLabel="Search country"
                            noEntriesFoundLabel="No countries found"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let country of filteredCountries$ | async" [value]="country">
                        {{ country }}
                    </mat-option>
                </mat-select>
                <mat-error>
                    Please provide a valid country
                </mat-error>
            </mat-form-field>
        </div>
        <button *ngIf="form.dirty && !isSubmitting" mat-flat-button color="primary">{{formButtonText}}</button>

        <div *ngIf="isSubmitting">Submitting...</div>
        <div *ngIf="submitSuccess && !form.dirty" class="success-message">{{submitSuccessMessage}}</div>
        <div *ngIf="submitError && !form.dirty" class="error-message">{{submitErrorMessage}}</div>

    </ng-container>
</form>