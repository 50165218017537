import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../modules/material/material.module';
import { FirestoreTimestampPipe } from './pipes/firestore-date.pipe';
import { ContainerComponent } from './components/container/container.component';
import { StatusComponent } from './components/status/status.component';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './components/header/header.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { RouterModule } from '@angular/router';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ContentBlockComponent } from './components/content-block/content-block.component';
import { FooterComponent } from './components/footer/footer.component';
import { BankAccountsComponent } from './components/bank-accounts/bank-accounts.component';
import { LoginComponent } from './components/login/login.component';
import { AuthContainerComponent } from './components/auth-container/auth-container.component';
import { FilesizePipe } from './pipes/filesize.pipe';
import { UnixTimestampPipe } from './pipes/unix-timestamp.pipe';
import { LogLinesComponent } from './components/log-lines/log-lines.component';
import { LogLineInfoComponent } from './components/log-lines/log-line-info/log-line-info.component';
import { LogLineComponent } from './components/log-lines/log-line/log-line.component';
import { ImportLogComponent } from './components/import-log/import-log.component';
import { CardComponent } from './components/card/card.component';
import { StatusIconComponent } from './components/status-icon/status-icon.component';
import { ExportIconComponent } from './components/export-icon/export-icon.component';
import { ImportIconComponent } from './components/import-icon/import-icon.component';
import { AlertComponent } from './components/alert/alert.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ExportLogComponent } from './components/export-log/export-log.component';
import { DateStringPipe } from './pipes/date-string.pipe';
import { UserFormComponent } from './components/user-form/user-form.component';



@NgModule({
  declarations: [
    DateStringPipe,
    FirestoreTimestampPipe,
    FilesizePipe,
    UnixTimestampPipe,
    ContainerComponent,
    StatusComponent,
    HeaderComponent,
    UserInfoComponent,
    SpinnerComponent,
    ContentBlockComponent,
    FooterComponent,
    BankAccountsComponent,
    LoginComponent,
    AuthContainerComponent,
    LogLinesComponent,
    LogLineInfoComponent,
    LogLineComponent,
    ImportLogComponent,
    ExportLogComponent,
    CardComponent,
    StatusIconComponent,
    ExportIconComponent,
    ImportIconComponent,
    AlertComponent,
    ConfirmDialogComponent,
    UserFormComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    DateStringPipe,
    FirestoreTimestampPipe,
    UnixTimestampPipe,
    FilesizePipe,
    ContainerComponent,
    StatusComponent,
    StatusIconComponent,
    SpinnerComponent,
    ContentBlockComponent,
    BankAccountsComponent,
    LoginComponent,
    AuthContainerComponent,
    LogLinesComponent,
    ImportLogComponent,
    ExportLogComponent,
    CardComponent,
    AlertComponent,
    ConfirmDialogComponent,
    UserFormComponent
  ]
})
export class SharedModule { }
