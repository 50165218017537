import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { from, Observable, of } from 'rxjs';
import { IUser } from '../model/user.model';
import firebase from 'firebase/app';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public set user(user: IUser | null) {
    localStorage.setItem('tactly-user', JSON.stringify(user));
  }

  public get user(): IUser | null {
    return JSON.parse(localStorage.getItem('tactly-user')!);
  }

  constructor(private auth: AngularFireAuth) {}

  public authState: Observable<firebase.User | null> = this.auth.authState;

  public onAuthStateChanged = this.auth.onAuthStateChanged;

  public isValid(): boolean {
    return this.user !== null ? true : false;
  }

  public login(email: string, password: string): Promise<any> {
    return this.auth
      .signInWithEmailAndPassword(email, password)
      .then((response) => {
        this.setUser(response.user);
        return true;
      })
      .catch((error) => false);
  }

  private setUser(user: any): void {
    // Ugly workaround
    const admins = [
      'r.brouwer@tactly.io',
      'k.daniel@tactly.io',
      's.daniel@tactbv.com',
    ];

    const role = admins.includes(user.email) ? 'admin' : 'user';

    this.user = {
      id: user.uid,
      firstname: '',
      lastname: '',
      displayName: user.displayName,
      role,
      email: ''
    };
  }

  public isAdmin(): boolean {
    return this.user?.role === 'admin';
  }

  public logout(): Promise<void> {
    this.user = null;
    return this.auth.signOut();
  }

  public getToken(): Observable<string | null> {
    return from(this.auth.currentUser).pipe(
      switchMap((user) => {
        if (!user) {
          return of(null);
        }
        return from(user.getIdToken());
      })
    );
  }

  public signInWithToken(token: string): void {
    this.auth.signInWithCustomToken(token).then((response) => {
      this.setUser(response.user);
    });
  }

  public async sendPasswordResetEmail(email: string): Promise<void> {
    try {
      await this.auth.sendPasswordResetEmail(email);
    } catch (error: any) {
      if (error.code === 'auth/user-not-found') {
        throw { status: 400, message: 'User does not exist' };
      } else {
        throw error;
      }
    }
  }
}
