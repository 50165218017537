import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IStatement } from '../../../../shared/model/statement.model';
import { IconService } from '../../../../shared/services/icon.service';
import { StatementService } from '../../../../shared/services/statement.service';

@Component({
  selector: 'app-statements',
  templateUrl: './statements.component.html',
  styleUrls: ['./statements.component.scss']
})
export class StatementsComponent implements OnInit {

  @Input()
  public bankAccountId!: number;

  public statements$!: Observable<IStatement[]>;

  public columns: string[] = [
    'number',
  ];

  constructor(
    private statementService: StatementService,
    private iconService: IconService,
  ) {
    this.iconService.loadIcons([
      'checked',
      'unchecked',
    ]);
  }

  public ngOnInit(): void {
    this.statements$ = this.statementService.getByBankAccountId(this.bankAccountId, 5);
  }

}
