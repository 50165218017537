import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.enrichRequestWithHeaders(request).pipe(
      switchMap((requestWithHeaders) => {
        return next.handle(requestWithHeaders);
      })
    );
  }

  private enrichRequestWithHeaders(
    request: HttpRequest<any>
  ): Observable<HttpRequest<any>> {
    return this.authService.getToken().pipe(
      map((token) => {
        if (!token || !request.url.includes('/v2/')) {
          return request;
        }
        return request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      })
    );
  }
}
