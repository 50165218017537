import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { IconService } from '../../services/icon.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  public sendingPWResetMail: boolean = false;

  public form: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', Validators.required),
  });

  public loginInvalid: boolean = false;

  constructor(
    private authService: AuthService,
    private iconService: IconService,
    private snackBar: MatSnackBar,
  ) {
    this.iconService.loadIcons([
      'account',
      'lock',
    ]);
  }

  public login(): void {
    if (this.form.valid) {
      const username: string = this.form.get('username')!.value;
      const password: string = this.form.get('password')!.value;
      this.authService.login(username, password).then((success: boolean) => {
        this.loginInvalid = !success;
      });
    }
  }

  public sendPasswordResetEmail(): void {
    this.sendingPWResetMail = true;
    const username: string = this.form.get('username')!.value;

    this.authService.sendPasswordResetEmail(username).then(() => {
      this.snackBar.open('Password reset email sent', 'Dismiss', {
        duration: 15000,
        panelClass: ['custom-snackbar']
      });
      this.sendingPWResetMail = false;
    }).catch((error) => {
      this.snackBar.open(error.message, 'Dismiss', {
        duration: 15000,
        panelClass: ['error-snackbar']
      });
      this.sendingPWResetMail = false;
    });
  }
}
