<app-content-block cssStyle="form">
  <form [formGroup]="form" (ngSubmit)="login()">
    <h2>Log In</h2>
    <mat-error *ngIf="loginInvalid">
      Incorrect email address and/or password
    </mat-error>
    <div>
      <mat-form-field class="full-width-input" appearance="outline">
        <mat-label>Email address</mat-label>
        <input matInput placeholder="Email address" formControlName="username" required>
        <mat-icon matSuffix svgIcon="account"></mat-icon>
        <mat-error>
          Please provide a valid email address
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width-input" appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput type="password" placeholder="Password" formControlName="password" required>
        <mat-icon matSuffix svgIcon="lock"></mat-icon>
        <mat-error>
          Please provide a valid password
        </mat-error>
      </mat-form-field>
    </div>
    <button mat-flat-button color="primary">Login</button><br>    
  </form>
</app-content-block>
<app-content-block>
  <div class="password-reset-link"> 
    <a *ngIf="!sendingPWResetMail" (click)="sendPasswordResetEmail()">Lost your password?</a>
  </div>  
</app-content-block>

