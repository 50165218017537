<app-container>  
    <app-content-block cssStyle="form">
        <form [formGroup]="form" (ngSubmit)="updateProfile()">
            <h1>Profile</h1>
            <ng-container *ngIf="user$ | async as user">
                <div>
                    <mat-form-field class="full-width-input" appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput placeholder="Email" formControlName="email">
                        <mat-error>
                            Please provide a valid email address
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="full-width-input" appearance="outline">
                        <mat-label>First Name</mat-label>
                        <input matInput placeholder="First Name" formControlName="firstname">
                        <mat-error>
                            Please provide a valid first name
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="full-width-input" appearance="outline">
                        <mat-label>Last Name</mat-label>
                        <input matInput placeholder="Last Name" formControlName="lastname">
                        <mat-error>
                            Please provide a valid last name
                        </mat-error>
                    </mat-form-field>
                </div>
                
                <div>
                    <mat-form-field class="full-width-input" appearance="outline">
                        <mat-label>Phone</mat-label>
                        <input matInput placeholder="Phone" formControlName="phoneNumber">
                        <mat-error>
                            Please provide a valid phone number
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="full-width-input" appearance="outline">
                        <mat-label>Country</mat-label>
                        <input matInput placeholder="Country" formControlName="country">
                        <mat-error>
                            Please provide a valid country
                        </mat-error>
                    </mat-form-field>
                </div>
                <button *ngIf="form.dirty && !isUpdating" mat-flat-button color="primary">Update Profile</button>

                <div *ngIf="isUpdating">Updating...</div>
                <div *ngIf="updateSuccess && !form.dirty" class="success-message">Profile updated successfully!</div>
                <div *ngIf="updateError && !form.dirty" class="error-message">Error updating profile. Please try again.</div>
                
            </ng-container>
        </form>
    </app-content-block>  
</app-container>