export const extraCountries: string[] = [
    'Anguilla', 'Antigua and Barbuda', 'Aruba', 'Bahamas', 'Barbados',
    'Bermuda', 'Bonaire', 'British Virgin Islands', 'Cayman Islands',
    'Cuba', 'Curaçao', 'Dominica', 'Dominican Republic', 'Grenada',
    'Guadeloupe', 'Haiti', 'Jamaica', 'Martinique', 'Montserrat',
    'Puerto Rico', 'Saint Barthelemy', 'Saint Kitts and Nevis',
    'Saint Lucia', 'Saint Martin', 'Saint Vincent and the Grenadines',
    'Sint Eustatius', 'Sint Maarten', 'Trinidad and Tobago',
    'Turks and Caicos Islands', 'US Virgin Islands'
];
