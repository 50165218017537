<app-container>
  <app-content-block *ngIf="bankAccount$ | async as bankAccount">
    <h1>
      <div class="status-icon" [ngClass]="{ active: bankAccount.active }"></div>
      Bank account - {{ bankAccount.name }}
      <span>({{ bankAccount.account }})</span>
      <ng-container *ngIf="canEditBankAccount$ | async">
        <button
          mat-icon-button
          color="basic"
          class="edit"
          [matMenuTriggerFor]="menu"
        >
          <mat-icon svgIcon="settings" class="company-settings"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="toggleActive(bankAccount)">
            <mat-icon svgIcon="checked"></mat-icon>
            {{ bankAccount.active ? 'Disable' : 'Enable' }} bank account
          </button>
          <button mat-menu-item [routerLink]="['/bank-accounts', bankAccount.id, 'edit']">
            <mat-icon svgIcon="edit"></mat-icon>
            Edit bank account
          </button>
          <button mat-menu-item class="delete" (click)="showDeleteDialog(bankAccount)">
            <mat-icon svgIcon="delete"></mat-icon>
            Delete bank account
          </button>
        </mat-menu>
      </ng-container>
    </h1>
    <app-connection
      [bankAccount]="bankAccount"
      [canEditBankAccount]="(canEditBankAccount$ | async) || false"
    ></app-connection>
    <div *ngIf="company$ | async as company" class="text-center">
      <button
        mat-flat-button
        color="primary"
        [routerLink]="['/companies', bankAccount.companyId]"
      >
        Return to {{ bankAccount.companyName }}
      </button>
    </div>
  </app-content-block>
</app-container>
