import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IUser } from 'src/app/shared/model/user.model';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-profile',  
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss']
})
export class ProfilePage implements OnInit, OnDestroy {
  user: IUser | null = null;
  isUpdating: boolean = false;
  updateSuccess: boolean = false;
  updateError: boolean = false;
  private readonly _subscriptions: Subscription = new Subscription();

  constructor(
    private userService: UserService
  ) {}

  ngOnInit(): void {    
    const userSubscription = this.userService.getCurrentUser().subscribe(
      (user) => {
        this.user = user;
      },
      (error) => {
        console.error(error);
      }

    );
    this._subscriptions.add(userSubscription);
  }  

  updateProfile(userData: IUser): void {
    this.isUpdating = true;
    this.updateSuccess = false;
    this.updateError = false;

    const updateSubscription = this.userService.updateCurrentUser(userData).subscribe(
      (updatedUser) => {
        this.isUpdating = false;
        this.updateSuccess = true;
        this.user = updatedUser;
      },
      (error) => {
        console.error(error);
        this.isUpdating = false;
        this.updateError = true;
      }
    );
    this._subscriptions.add(updateSubscription);
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
