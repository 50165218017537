<div class="connection">

  <div class="import">
    <div class="box">
      <app-connection-import
        [bankAccount]="bankAccount"
        [canEditBankAccount]="canEditBankAccount"
      ></app-connection-import>
    </div>
    <button
      *ngIf="canEditBankAccount"
      mat-button
      color="basic"
      class="developer"
      (click)="runSingleImport()"
    >
      <mat-icon matSuffix svgIcon="play"></mat-icon>
      Run single import
    </button>
    <button
      *ngIf="canEditBankAccount"
      mat-button
      color="basic"
      class="developer"
      [routerLink]="['/developer/import-tools', bankAccount.id]"
    >
      <mat-icon matSuffix svgIcon="bug"></mat-icon>
      Developer tools
    </button>
  </div>

  <div class="arrow">
    <img src="/assets/images/arrow-right-white.png" />
  </div>

  <div class="application">
    <div class="box tactly">
      <div class="tactly-logo">
        <img src="/assets/images/tactly/logo/logo-tactly-white-300x200.png" />
      </div>
      <app-statements *ngIf="bankAccount.importType || bankAccount.exportType" [bankAccountId]="bankAccount.id"></app-statements>
    </div>
    <button
      *ngIf="canEditBankAccount"
      mat-button
      color="basic"
      class="developer"
      (click)="runSingleImportExport()"
    >
      <mat-icon matSuffix svgIcon="play"></mat-icon>
      Run single import/export
    </button>
  </div>

  <div class="arrow">
    <img src="/assets/images/arrow-right-white.png" />
  </div>

  <div class="export">
    <div class="box">
      <app-connection-export [bankAccount]="bankAccount"></app-connection-export>
    </div>
    <button
      *ngIf="canEditBankAccount"
      mat-button
      color="basic"
      class="developer"
      (click)="runSingleExport()"
    >
      <mat-icon matSuffix svgIcon="play"></mat-icon>
      Run single export
    </button>
  </div>

</div>