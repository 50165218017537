declare var Plaid:any;

import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BankAccountService } from 'src/app/shared/services/bank-account.service';
import { Router } from '@angular/router';
import { IBankAccount } from 'src/app/shared/model/bank-account.model';
import { ImportSettingsPlaidService } from './import-settings-plaid.service';
import { IImportSettingsPlaid } from './import-settings-plaid.model';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-import-settings-plaid',
  templateUrl: './import-settings-plaid.component.html',
  styleUrls: ['./import-settings-plaid.component.scss']
})
export class ImportSettingsPlaidComponent implements OnInit {

  @Input()
  public bankAccount!: IBankAccount;

  public linkToken$!: Observable<string>;
  public connected$ = of(false);

  public form = new FormGroup({
    startDate: new FormControl('', Validators.required),
  });

  private settings!: IImportSettingsPlaid;

  constructor(
    private bankAccountService: BankAccountService,
    private importSettingsPlaidService: ImportSettingsPlaidService,
    private router: Router,
  ) { }

  public ngOnInit(): void {
    // Save import type
    this.bankAccountService.update({ ...this.bankAccount });

    this.connected$ = this.importSettingsPlaidService.get(this.bankAccount.id).pipe(
      tap(settings => settings && this.patchFormValues(settings)),
      tap(settings => this.settings = settings),
      map(settings => !!(settings && settings.accessToken)),
    );
    this.linkToken$ = this.importSettingsPlaidService.getLinkToken(this.bankAccount.id);
  }

  private patchFormValues(settings: IImportSettingsPlaid): void {
    const startDate = moment(settings.startDate).format('YYYY-MM-DD');
    this.form.patchValue({ startDate })
  }

  public linkAccount(linkToken: string): void {
    const bankAccountId = this.bankAccount.id;
    const settingsService = this.importSettingsPlaidService;

    const linkHandler = Plaid.create({
      token: linkToken,
      product: ['auth'],
      selectAccount: true,
      onLoad: function () {
        console.debug('loaded');
      },
      onSuccess: function (publicToken: string, metadata: any) {
        if (metadata != null) {
          settingsService.getAccessToken(publicToken).pipe(
            switchMap((accessToken) => settingsService.save(bankAccountId, {
              accessToken,
            }))
          ).subscribe();
        }
      },
      onExit: function (err: Error, metadata: any) {
        if (err != null) {
          console.error(err);
        }
      }
    });

   linkHandler.open();
  }

  public disconnect(): void {
    this.importSettingsPlaidService.save(this.bankAccount.id, { accessToken: '' });
  }

  public async save(): Promise<void> {
    const startDateValue = this.form.get('startDate')?.value;
    const startDate = startDateValue ? moment(startDateValue).format('YYYY-MM-DD') : undefined;
    await this.importSettingsPlaidService.save(this.bankAccount.id, { ...this.settings, startDate });
    this.router.navigate(['/bank-accounts', this.bankAccount.id]);
  }

}
