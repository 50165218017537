import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { delay, filter, merge, tap, timeout, timeoutWith } from 'rxjs/operators';
import { interval, throwError, TimeoutError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  async canActivate(): Promise<boolean> {

    if (!this.authService.isValid()) {
      // location.href = '/login';
      return false;
    }

    await this.waitForAuthInit();

    const token = await this.authService.getToken().toPromise();

    return !!token;
  }

  private async waitForAuthInit() {
    let unsubscribe: Promise<firebase.default.Unsubscribe>;
    await new Promise<void>((resolve) => {
      unsubscribe = this.authService.onAuthStateChanged((_) => resolve());
    });
    (await unsubscribe!)();
  }
}
