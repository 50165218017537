import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { IUser } from '../model/user.model';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public getCollection(): string {
    return 'users';
  }

  constructor(
    private auth: AngularFireAuth,
    private http: HttpClient,
    private authService: AuthService
  ) { }

  private get(firebaseId: string): Observable<IUser> {
    return this.http.get<IUser>(
      `${environment.api}/v2/users/${firebaseId}`
    );
  }

  public getCurrentUser(): Observable<IUser> {
    const defaultUser: IUser = {
      firstname: 'Default',
      lastname: 'User',
      displayName: 'Default User',
      role: 'user',
      email: 'default@notmail.com'
    };
    return this.auth.authState.pipe(
      switchMap((authState) => {
        if (authState) {
          return this.get(authState.uid).pipe(
            map((user) => {  
              if (!user.firstname || !user.lastname) {
                const displayName = user.displayName?.split(' ');
                user.firstname = (displayName && displayName[0]) ? displayName[0] : '';
                user.lastname = (displayName && displayName[1]) ? displayName.slice(1).join(' ') : '';
              }
              return user;
            })
          );
        } else {
          return of(defaultUser);
        }
      })
    );
  }

  private update(user: IUser, firebaseId: string): Observable<any> {
    return this.http.put<IUser>(
      `${environment.api}/v2/users/${firebaseId}`,
      user
    );
  }

  public updateCurrentUser(user: IUser): Observable<IUser> {
    return this.auth.authState.pipe(
      switchMap((authState) => {
        if (authState) {         
          return this.update(user, authState.uid).pipe(
            map((response) => {
              if (response.token) {
                this.authService.signInWithToken(response.token);
              }
              return response;
            })
          );
        } else {
          return of(user);
        }
      })
    );
  }

}
