import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  constructor(
    private http: HttpClient,
  ) { }

  public singleImport(bankAccountId: number): Observable<any> {
    const url = `${environment.api}/importSingleStatement?bankAccountId=${bankAccountId}`;
    return this.http.get(url);
  }

  public singleExport(bankAccountId: number): Observable<any> {
    const url = `${environment.api}/exportSingleStatement?bankAccountId=${bankAccountId}`;
    return this.http.get(url);
  }

}
