<app-container>

  <app-content-block cssStyle="form">

    <ng-container *ngIf="company$ | async as company">

      <form [formGroup]="form">

        <h1>Invite user to {{company.name}}</h1>

        <div [formGroup]="form">

          <app-alert *ngIf="error" type="error">
            {{ error }}
          </app-alert>

          <div>
            <mat-form-field appearance="outline">
              <mat-label>Email</mat-label>
              <input formControlName="email" matInput placeholder="Email">
              <mat-icon matSuffix svgIcon="email"></mat-icon>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field appearance="outline">
              <mat-label>Role</mat-label>
              <select matNativeControl formControlName="role">
                <option *ngFor="let role of ['owner', 'authorized']" [value]="role">{{ roleMetaData[role].name }}</option>
              </select>
              <mat-icon matSuffix svgIcon="user"></mat-icon>
            </mat-form-field>
          </div>

        </div>

        <div class="buttons">
          <button mat-flat-button color="primary" (click)="submit(company)">Invite user</button>
          <button mat-stroked-button [routerLink]="['/companies', company.id, 'users']">Cancel</button>
        </div>

      </form>

    </ng-container>

  </app-content-block>

</app-container>