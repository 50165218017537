
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IconService } from '../../../../shared/services/icon.service';
import { ICompany } from '../../../../shared/model/company.model';
import { CompanyService } from '../../../../shared/services/company.service';
import { catchError } from 'rxjs/operators';
import { roleMetaData } from '../../../../shared/model/role-meta-data';
import { CompanyUserService } from '../../../../shared/services';

@Component({
  selector: 'app-users-invite',
  templateUrl: './users-invite.page.html',
  styleUrls: ['./users-invite.page.scss']
})
export class UsersInvitePage implements OnInit {

  public company$!: Observable<ICompany>;

  public error: string = '';

  public roleMetaData: any = roleMetaData;

  public form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    role: new FormControl('authorized', Validators.required),
  });

  constructor(
    private companyService: CompanyService,
    private companyUserService: CompanyUserService,
    private iconService: IconService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.iconService.loadIcons([
      'email',
      'user',
    ]);
    this.form.get('email')?.valueChanges.subscribe(() => {
      this.error = '';
    })
  }

  public ngOnInit(): void {
    const companyId = Number(this.route.snapshot.paramMap.get('companyId'));
    this.company$ = this.companyService.get(companyId);
  }

  public submit(company: ICompany): void {

    this.form.get('email')?.markAsTouched();

    if (company.id && this.form.valid) {
      const email = this.form.get('email')?.value;
      const role = this.form.get('role')?.value;
      this.companyUserService.invite(company.id, email, role).pipe(
        catchError((error) => {
          this.error = error?.error?.error || 'Error inviting user';
          return EMPTY;
        }),
      ).subscribe(() => {
        this.router.navigate(['/companies', company.id, 'users']);
      });
    }
  }

}
