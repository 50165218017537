import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent, IConfirmData } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { IBankAccount } from '../../../../shared/model/bank-account.model';
import { ICompany } from '../../../../shared/model/company.model';
import { BankAccountService } from '../../../../shared/services/bank-account.service';
import { CompanyService } from '../../../../shared/services/company.service';
import { IconService } from '../../../../shared/services/icon.service';
import { CompanyPermission } from '../../../../shared/model/company-permission';
import { PermissionService } from '../../../../shared/services/permission.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.page.html',
  styleUrls: ['./details.page.scss']
})
export class DetailsPage implements OnInit {

  public company$!: Observable<ICompany>;
  public bankAccounts$!: Observable<IBankAccount[]>;
  public canEditCompany$!: Observable<boolean>;
  public canCreateBankAccount$!: Observable<boolean>;

  constructor(
    private permissionService: PermissionService,
    private companyService: CompanyService,
    private bankAccountService: BankAccountService,
    private route: ActivatedRoute,
    private iconService: IconService,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.iconService.loadIcons([
      'edit',
      'delete',
      'settings',
      'users',
    ]);
  }

  public ngOnInit(): void {
    const companyId = Number(this.route.snapshot.paramMap.get('companyId'));
    if (companyId) {
      this.company$ = this.companyService.get(companyId);
      this.bankAccounts$ = this.bankAccountService.getByCompanyId(companyId);
      this.canEditCompany$ = this.permissionService.hasCompanyPermission(companyId, CompanyPermission.EDIT);
      this.canCreateBankAccount$ = this.permissionService.hasCompanyPermission(companyId, CompanyPermission.CREATE_BANK_ACCOUNT);
    }
  }

  public showDeleteDialog(company: ICompany): void {
    const data: IConfirmData = {
      title: `Delete company "${company.name}"?`,
      message: 'This will delete the company and it\'s bank accounts and cannot be undone.'
    };
    const dialog = this.dialog.open(ConfirmDialogComponent, { data });
    dialog.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.delete(company);
      }
    });
  }

  public delete(company: ICompany): void {
    company.deleted = true;
    this.companyService.update(company).then(() => {
      this.router.navigate(['/companies']);
    });
  }

}
