import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IImportSettingsPlaid } from './import-settings-plaid.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImportSettingsPlaidService {

  constructor(
    private http: HttpClient,
  ) { }

  public getLinkToken(bankAccountId: number): Observable<string> {
    const url = `${environment.api}/import-plaid-linkToken?bankAccountId=${bankAccountId}`;
    return this.http.get<{linkToken: string}>(url).pipe(
      map((response) => response.linkToken),
    );
  }

  public getAccessToken(publicToken: string): Observable<string> {
    const url = `${environment.api}/import-plaid-accessToken?publicToken=${publicToken}`;
    return this.http.get<{accessToken: string}>(url).pipe(
      map((response) => response.accessToken),
    );
  }

  public get(bankAccountId: number): Observable<IImportSettingsPlaid> {
    const url = `${environment.api}/v2/bank-accounts/${bankAccountId}/import-settings/plaid`;
    return this.http.get<IImportSettingsPlaid>(url);
  }

  public save(bankAccountId: number, settings: IImportSettingsPlaid): Promise<void> {
    const url = `${environment.api}/v2/bank-accounts/${bankAccountId}/import-settings/plaid`;
    return this.http.post<void>(url, settings).toPromise();
  }

}
