import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IBankAccount } from '../../../../shared/model/bank-account.model';
import { environment } from '../../../../../environments/environment';
import { IDivision, IExportSettingsExactOnline, IGLAccount, IJournal } from './export-settings-exact-online.model';
import { BankAccountService } from '../../../../shared/services/bank-account.service';

@Injectable({
  providedIn: 'root'
})
export class ExportSettingsExactOnlineService {

  constructor(
    private http: HttpClient,
    private bankAccountService: BankAccountService,
  ) { }

  public get(bankAccountId: number): Observable<IExportSettingsExactOnline> {
    const url = `${environment.api}/v2/bank-accounts/${bankAccountId}/export-settings/exact-online`;
    return this.http.get<IExportSettingsExactOnline>(url);
  }

  public getConnectedBankAccount(companyId: number): Observable<IBankAccount> {
    return this.bankAccountService.getByCompanyId(companyId).pipe(
      switchMap(bankAccounts => {
        const observables = bankAccounts.map(bankAccount => {
          return this.get(bankAccount.id).pipe(
            map(settings => ({ bankAccount, settings })),
          );
        });
        return combineLatest(observables);
      }),
      map((values) => {
        return values.filter(({settings}) => settings && settings?.accessToken);
      }),
      map(value => value[0].bankAccount),
    );
  }

  public getGLAccounts(bankAccountId: number): Observable<IGLAccount[]> {
    const url = `${environment.api}/exactOnlineGlAccounts?bankAccountId=${bankAccountId}`;
    return this.http.get<IGLAccount[]>(url);
  }

  public getJournals(bankAccountId: number): Observable<IJournal[]> {
    const url = `${environment.api}/exactOnlineJournals?bankAccountId=${bankAccountId}`;
    return this.http.get<IJournal[]>(url);
  }

  public getDivisions(bankAccountId: number): Observable<IDivision[]> {
    const url = `${environment.api}/exactOnlineDivisions?bankAccountId=${bankAccountId}`;
    return this.http.get<IDivision[]>(url);
  }

  public save(bankAccountId: number, settings: IExportSettingsExactOnline): Promise<void> {
    const url = `${environment.api}/v2/bank-accounts/${bankAccountId}/export-settings/exact-online`;
    return this.http.post<void>(url, settings).toPromise();
  }

}
