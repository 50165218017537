import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ICompany } from '../../../../shared/model/company.model';
import { CompanyService } from '../../../../shared/services/company.service';
import { UserService } from '../../../../shared/services/user.service';
import { ConfirmDialogComponent, IConfirmData } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { roleMetaData } from '../../../../shared/model/role-meta-data';
import { CompanyUserService } from '../../../../shared/services';
import { CompanyUser } from '../../../../shared/model';

@Component({
  selector: 'app-users',
  templateUrl: './users.page.html',
  styleUrls: ['./users.page.scss']
})
export class UsersPage implements OnInit {

  private companyId!: number;
  public user$ = this.userService.getCurrentUser();
  public company$!: Observable<ICompany>;
  public companyUsers$!: Observable<CompanyUser[]>;

  public roleMetaData: any = roleMetaData;

  constructor(
    private userService: UserService,
    private companyService: CompanyService,
    private companyUserService: CompanyUserService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) { }

  public ngOnInit(): void {
    this.companyId = Number(this.route.snapshot.paramMap.get('companyId'));
    this.company$ = this.companyService.get(this.companyId);
    this.companyUsers$ = this.companyUserService.getByCompanyId(Number(this.companyId));
  }

  public showDeleteDialog(companyUser: CompanyUser): void {
    const data: IConfirmData = {
      title: `Delete permission for "${companyUser.name}"?`,
      message: 'This remove the permissions for this user on this company and cannot be undone.'
    };
    const dialog = this.dialog.open(ConfirmDialogComponent, { data });
    dialog.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.delete(companyUser).then(() => {
          // Reload the list of users. There should be a nicer way to do this
          this.companyUsers$ = this.companyUserService.getByCompanyId(Number(this.companyId));
        });
      }
    });
  }

  public async delete(companyUser: CompanyUser): Promise<void> {
    if (this.companyId) {
      await this.companyUserService.delete(companyUser.companyId, companyUser.userId);
    }
  }

}
