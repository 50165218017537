import { NgModule } from '@angular/core';
import { LoginPage } from './pages/login/login.page';
import { AuthRoutingModule } from './auth-routing.module';
import { LogoutPage } from './pages/logout/logout.page';
import { SharedModule } from '../../shared/shared.module';
import { ProfilePage } from './pages/profile/profile.page';

@NgModule({
  declarations: [
    LoginPage,
    LogoutPage,
    ProfilePage
  ],
  imports: [
    AuthRoutingModule,
    SharedModule,
  ],
  exports: [],
})
export class AuthModule { }
