import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { IUser } from 'src/app/shared/model/user.model';
import { UserService } from 'src/app/shared/services/user.service';
@Component({
  selector: 'app-profile',  
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss']
})
export class ProfilePage implements OnInit {

  form!: FormGroup;
  user$: Observable<IUser> | undefined;
  isUpdating: boolean = false;
  updateSuccess: boolean = false;
  updateError: boolean = false;

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {   
    this.user$ = this.userService.getCurrentUser();

    this.form = this.fb.group({
      email: [{ value: '', disabled: true }, [Validators.required, Validators.email]],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],      
      phoneNumber: ['', Validators.required],
      country: ['', Validators.required]
    });

    this.user$.subscribe((user) => {
      this.form.patchValue(user);
    });
  }

  updateProfile(): void {
    if (this.form.valid) {
        this.isUpdating = true;
        this.updateSuccess = false;
        this.updateError = false;

        const user: IUser = this.form.value;
        user.displayName = `${user.firstname} ${user.lastname}`;

        this.userService.updateCurrentUser(user).pipe(take(1)).subscribe(
            (updatedUser) => {
                this.form.patchValue(updatedUser);
                this.isUpdating = false;
                this.updateSuccess = true;
                this.form.markAsPristine();
            },
            (error) => {
                console.error(error);
                this.isUpdating = false;
                this.updateError = true;
            }
        );
    }
  }
}
