<div class="importType" *ngIf="bankAccount.importType; else selectImportType">
  <div
    class="select"
    [ngClass]="{'clickable-item': canEditBankAccount}"
    [routerLink]="canEditBankAccount ? ['/bank-accounts', bankAccount.id, 'import-settings'] : null"
  >
    <img [src]="imageSrc" />
  </div>
  <app-import-logs [bankAccountId]="bankAccount.id"></app-import-logs>
</div>

<ng-template #selectImportType>
  <div
    class="select"
    [ngClass]="{'clickable-item': canEditBankAccount}"
    [routerLink]="canEditBankAccount ? ['/bank-accounts', bankAccount.id, 'import-settings'] : null"
  >
    <h3>{{canEditBankAccount ? 'From where can we import your transactions?' : 'There is no import type configured for this bank account'}}</h3>
  </div>
</ng-template>
