import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BankAccountService } from '../../../../shared/services/bank-account.service';
import { IBankAccount } from '../../../../shared/model/bank-account.model';
import { IDivision, IExportSettingsExactOnline, IGLAccount, IJournal } from './export-settings-exact-online.model';
import { ExportSettingsExactOnlineService } from './export-settings-exact-online.service';
import { environment } from '../../../../../environments/environment';
import { Observable, timer } from 'rxjs';
import { IconService } from '../../../../shared/services/icon.service';
import { catchError, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-export-settings-exact-online',
  templateUrl: './export-settings-exact-online.component.html',
  styleUrls: ['./export-settings-exact-online.component.scss']
})
export class ExportSettingsExactOnlineComponent implements OnInit {

  @Input()
  public bankAccount!: IBankAccount;

  public glAccounts$!: Observable<IGLAccount[]>;
  public journals$!: Observable<IJournal[]>;
  public divisions$!: Observable<IDivision[]>;

  public connectedBankAccount$!: Observable<IBankAccount>;

  private exactOnlineSettings!: IExportSettingsExactOnline;

  public error!: string;

  public form = new FormGroup({
    division: new FormControl('', Validators.required),
    journalCode: new FormControl('', Validators.required),
    glAccountId: new FormControl('', Validators.required),
  });

  public connected = false;
  public linked = false;

  constructor(
    private bankAccountService: BankAccountService,
    private exportSettingsExactOnlineService: ExportSettingsExactOnlineService,
    private iconService: IconService,
    private router: Router,
  ) {
    this.iconService.loadIcons([
      'company',
    ]);
  }

  public ngOnInit(): void {
    this.exportSettingsExactOnlineService.get(this.bankAccount.id).subscribe((settings) => {
      this.connected = (settings && !!settings.accessToken && !!settings.refreshToken);
      this.linked = (settings && !!settings.linkedBankAccount);
      this.form.patchValue(settings);
      this.exactOnlineSettings = settings;
    });
    this.setDelayedObservables();
    this.connectedBankAccount$ = this.exportSettingsExactOnlineService.getConnectedBankAccount(this.bankAccount.companyId);
  }

  private setDelayedObservables() {
    this.error = '';
    this.divisions$ = timer(0).pipe(
      switchMap(() => this.exportSettingsExactOnlineService.getDivisions(this.bankAccount.id)),
      catchError((error) => {
        this.error = 'Error loading data from Exact Online';
        throw(error);
      }),
    );
    this.glAccounts$ = timer(2000).pipe(
      switchMap(() => this.exportSettingsExactOnlineService.getGLAccounts(this.bankAccount.id)),
      catchError((error) => {
        this.error = 'Error loading data from Exact Online';
        throw(error);
      }),
    );
    this.journals$ = timer(2000).pipe(
      switchMap(() => this.exportSettingsExactOnlineService.getJournals(this.bankAccount.id)),
      catchError((error) => {
        this.error = 'Error loading data from Exact Online';
        throw(error);
      }),
    );
  }

  public updateDivision(): void {
    const divisionCode = Number(this.form.get('division')?.value);
    const bankAccountId = this.bankAccount.id;
    this.exportSettingsExactOnlineService.save(bankAccountId, {
      division: divisionCode,
      journalCode: undefined,
      glAccountId: undefined,
    }).then(() => {
      this.form.patchValue({ journalCode: '', glAccountId: '' });
      this.setDelayedObservables();
    });
  }

  public save(): void {
    if (this.form.valid) {
      const settings = this.parseSettings();
      const bankAccountId = this.bankAccount.id;
      const saveBankAccount = this.bankAccountService.update(this.bankAccount);
      const saveSettings = this.exportSettingsExactOnlineService.save(bankAccountId, settings);
      Promise.all([
        saveBankAccount,
        saveSettings,
      ]).then(() => {
        this.router.navigate(['/bank-accounts', bankAccountId]);
      });
    }
  }

  public connect(): void {
    // Make sure the exportType is saved
    this.bankAccountService.update({ ...this.bankAccount }).then(() => {
      window.location.href = `${environment.api}/exactOnlineAuthorize?bankAccountId=${this.bankAccount.id}`;
    });
  }

  public async disconnect(): Promise<void> {
    const bankAccountId = this.bankAccount.id;
    await this.exportSettingsExactOnlineService.save(bankAccountId, { accessToken: '', refreshToken: '' });
    location.reload();
  }

  public link(linkedBankAccount: number): void {
    const bankAccountId = this.bankAccount.id;
    this.exportSettingsExactOnlineService.save(bankAccountId, { linkedBankAccount }).then(() => {
      this.bankAccountService.update({ ...this.bankAccount }).then(() => {
        window.location.reload();
      });
    });
  }

  public unlink(): void {
    const bankAccountId = this.bankAccount.id;
    this.exportSettingsExactOnlineService.save(bankAccountId, { linkedBankAccount: null });
  }

  public cancel(): void {
    this.bankAccount.exportType = '';
    this.bankAccountService.update({ ...this.bankAccount }).then(() => {
      this.router.navigate(['/bank-accounts', this.bankAccount.id]);
    });
  }

  private parseSettings(): IExportSettingsExactOnline {
    return {
      ...this.exactOnlineSettings,
      journalCode: Number(this.form.get('journalCode')?.value),
      glAccountId: this.form.get('glAccountId')?.value,
    };
  }

}
