import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { CompanyPermission } from '../model/company-permission';
import { BankAccountPermission } from '../model/bank-account-permission';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(
    private authService: AuthService,
    private http: HttpClient,
  ) {}

  private getCompanyPermissions(companyId: number) {
    const url = `${environment.api}/v2/companies/${companyId}/permissions`;
    return this.http.get<CompanyPermission[]>(url);
  }

  public hasCompanyPermission(
    companyId: number,
    permission: CompanyPermission,
  ): Observable<boolean> {
    const user = this.authService.user;
    if (user?.id) {
      return this.getCompanyPermissions(Number(companyId)).pipe(
        map(permissions => permissions.includes(permission)),
      )
    }
    return of(false);
  }

  private getBankAccountPermissions(bankAccountId: number) {
    const url = `${environment.api}/v2/bank-accounts/${bankAccountId}/permissions`;
    return this.http.get<BankAccountPermission[]>(url);
  }

  public hasBankAccountPermission(
    bankAccountId: number,
    permission: BankAccountPermission,
  ): Observable<boolean> {
    const user = this.authService.user;
    if (user?.id) {
      return this.getBankAccountPermissions(Number(bankAccountId)).pipe(
        map(permissions => permissions.includes(permission)),
      )
    }
    return of(false);
  }
}
