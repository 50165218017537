import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPage } from './pages/login/login.page';
import { LogoutPage } from './pages/logout/logout.page';
import { ProfilePage } from './pages/profile/profile.page';
import { AuthGuard } from 'src/app/shared/guards/auth';

const routes: Routes = [
  {
    path: 'login',
    component: LoginPage,
  },
  {
    path: 'logout',
    component: LogoutPage,
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    component: ProfilePage,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
