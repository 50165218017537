import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IBankAccount } from '../../../../shared/model/bank-account.model';
import { BankAccountService } from '../../../../shared/services/bank-account.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.page.html',
  styleUrls: ['./edit.page.scss']
})
export class EditPage implements OnInit {

  public bankAccount$!: Observable<IBankAccount>;

  public form = new FormGroup({
    name: new FormControl('', Validators.required),
    account: new FormControl('', Validators.required),
    importExportGroup: new FormControl('', Validators.required),
  });

  constructor(
    private bankAccountService: BankAccountService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  public ngOnInit(): void {
    const bankAccountId = Number(this.route.snapshot.paramMap.get('bankAccountId'));
    this.bankAccount$ = this.bankAccountService.get(bankAccountId).pipe(
      tap((bankAccount) => this.form.patchValue({
        name: bankAccount.name,
        account: bankAccount.account,
        importExportGroup: bankAccount.importExportGroup,
      })),
    );
  }

  public submit(bankAccount: IBankAccount): void {
    if (this.form.valid) {
      bankAccount = this.updateBankAccount(bankAccount);
      this.bankAccountService.update(bankAccount).then(() => {
        this.router.navigate(['/bank-accounts', bankAccount.id]);
      })
    }
  }

  private updateBankAccount(bankAccount: IBankAccount): IBankAccount {
    bankAccount.name = this.form.get('name')?.value;
    bankAccount.account = this.form.get('account')?.value;
    bankAccount.importExportGroup = this.form.get('importExportGroup')?.value;
    return bankAccount;
  }

}
